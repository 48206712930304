import "./index.css";

import { useEffect, useState } from "react";

import Button from "./Button";
import IncomeCard from "./components/IncomeCard";
import Navbar from "./components/Navbar";
import incomeJson from "./income.json";
import loadProfessions from "./loadProfessions";

export type Profession = {
  description: string;
  salary: number;
};

function App() {
  const [includeBaseSalary, setIncludeBaseSalary] = useState(true);
  const [includeBonus, setIncludeBonus] = useState(true);
  const [includeOvertime, setIncludeOvertime] = useState(true);
  const [displayChange, setDisplayChange] = useState(false);
  const [professions, setProfessions] = useState<Profession[]>([]);

  const [rotateArrow, setRotateArrow] = useState(false);
  const handleRotate = () => setRotateArrow(!rotateArrow);
  const rotate = rotateArrow ? "rotate(180deg)" : "rotate(0)";

  useEffect(() => {
    setProfessions(loadProfessions(incomeJson));
  }, []);

  const renderIncomeCards = () => {
    const incomeCards: JSX.Element[] = [];
    professions.forEach((data, index) => {
      incomeCards.push(
        <IncomeCard
          rank={index + 1}
          profession={data.description}
          salary={data.salary}
        />
      );
    });

    return incomeCards;
  };

  return (
    <div id="app" className="h-screen w-screen">
      <Navbar />
      <div className="h-full w-full">
        <div className="container flex flex-col max-w-3xl mb-4 mx-auto">
          <h2 className="font-extrabold my-16 sm:my-24 mx-4 sm:mx-6 text-3xl sm:text-5xl text-center text-slate-700">
            Sammenlign inntekten din med andre yrker
          </h2>
          <input
            className="border-2 border-slate-500 focus:outline-none mb-6 sm:mb-8 mx-4 sm:mx-6 placeholder:text-slate-500 px-4 py-1.5 rounded-lg"
            placeholder="Søk etter yrke"
          />
          <div className="flex mb-6 sm:mb-8 no-scrollbar overflow-x-auto pl-4 sm:pl-6">
            <Button
              className="mr-2 sm:mr-3"
              enabled={includeBaseSalary}
              onClick={() => setIncludeBaseSalary(!includeBaseSalary)}
              title="Fastlønn"
            />
            <Button
              className="mr-2 sm:mr-3"
              enabled={includeBonus}
              onClick={() => setIncludeBonus(!includeBonus)}
              title="Bonus"
            />
            <Button
              className="mr-3 sm:mr-4"
              enabled={includeOvertime}
              onClick={() => setIncludeOvertime(!includeOvertime)}
              title="Overtid"
            />
            <div className="border-l-2 border-slate-400 mr-3 sm:mr-4 my-1"></div>
            <Button
              className="mr-3 sm:mr-4"
              enabled={displayChange}
              onClick={() => setDisplayChange(!displayChange)}
              title="Endring"
            />
          </div>
        </div>
        <div className="container h-full max-w-3xl mb-6 mx-auto">
          <div className="flex items-center mb-2 mt-4 px-8 sm:px-10 w-full">
            <div className="w-16"></div>
            <div className="flex mb-1 w-full">
              <div className="w-8/12">Yrke</div>
              <div className="flex justify-end w-4/12" onClick={handleRotate}>
                Årslønn
                <img
                  alt="arrow"
                  style={{ transform: rotate }}
                  className="w-6"
                  src="/images/grey-arrow.png"
                />
              </div>
            </div>
          </div>
          <div className="border-b-2 border-slate-200 mx-2 sm:mx-4"></div>
          <div className="pt-4">{renderIncomeCards()}</div>
        </div>
      </div>
    </div>
  );
}

export default App;
