import React from "react";

export default function IncomeCard({
  profession,
  rank,
  salary,
}: {
  profession: string;
  rank: number;
  salary: number;
}) {
  const numberWithCommas = (number: number) =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return (
    <div className="bg-slate-50 flex item-center mb-4 mx-4 sm:mx-6 p-4 rounded-lg shadow-md">
      <div className="flex items-center justify-start w-16">{rank}.</div>
      <div className="flex w-full">
        <div className="flex items-center justify-start w-8/12">
          {profession}
        </div>
        <div className="flex items-center justify-end w-4/12">
          {numberWithCommas(salary * 12)}
        </div>
      </div>
    </div>
  );
}
