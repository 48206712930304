import React from "react";

export default function Button({
  children,
  className,
  enabled,
  onClick,
  title = "Title",
}: {
  children?: JSX.Element;
  className?: string;
  enabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;
}) {
  const backgroundColor = enabled ? "bg-green-100" : "bg-white";
  const borderColor = enabled ? "border-green-600" : "border-slate-500";
  const hoverColor = enabled ? "hover:bg-green-200" : "hover:bg-slate-100";
  const textColor = enabled ? "text-green-600" : "text-slate-500";

  return (
    <button
      className={`${backgroundColor} border-2 ${borderColor} font-medium ${hoverColor} px-3 sm:px-4 py-1.5 sm:py-2 rounded-full text-sm sm:text-md ${textColor} ${className}`}
      onClick={onClick}
    >
      {children || title}
    </button>
  );
}
