import React from "react";

export default function Navbar() {
  return (
    <nav className="bg-white border-b-2">
      <div className="container flex items-center max-w-3xl mx-auto px-4 sm:px-6 py-4 sm:py-6">
        <a href="/" className="font-semibold text-xl tracking-tight">
          <span className=" text-black ">inntekt</span>
          <span className="text-slate-500">.net</span>
        </a>
      </div>
    </nav>
  );
}
