import { Profession } from "./App";

const loadProfessions = (rawIncomeData: any): Profession[] => {
  const categoryKeys = Object.keys(rawIncomeData.dimension.Yrke.category.index);
  const professions: Profession[] = [];
  categoryKeys.forEach((key) => {
    const index = rawIncomeData.dimension.Yrke.category.index[key];
    const description = rawIncomeData.dimension.Yrke.category.label[key];
    const salary = rawIncomeData.value[index];
    if (salary) {
      professions.push({
        description: description,
        salary: salary,
      });
    }
  });
  return professions.sort((a, b) => b.salary - a.salary);
};

export default loadProfessions;
